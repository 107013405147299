<template>
  <b-card-code title="推广员信息填写">
    <validation-observer ref="loginForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="submit">
        <b-row>
          <b-col md="6" xl="4">
            <b-form-group label="微信名称" label-for="wechatname">
              <b-form-input id="readOnlyInput" :value="wechatname" readonly />
            </b-form-group>
          </b-col>
          <!-- phone number -->
          <b-col md="6" xl="4">
            <b-form-group label="电话号码" label-for="phone">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required"
              >
                <b-input-group>
                  <cleave
                    id="phone"
                    v-model="phone"
                    class="form-control"
                    :raw="false"
                    :options="options.phone"
                    placeholder="136 8888 8888"
                  />
                </b-input-group>
                <small class="text-danger" v-if="errors[0]"
                  >请填写电话号码</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4">
            <b-form-group label="渠道" label-for="channelid">
              <v-select
                v-model="selected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="ChannelOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4">
            <b-form-group label="备注" label-for="remark">
              <b-form-textarea
                id="textarea-default"
                v-model="remark"
                placeholder="备注"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button variant="relief-success" type="submit"> 提交 </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import vSelect from "vue-select";
import { required, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "cleave.js/dist/addons/cleave-phone.cn";
export default {
  components: {
    BCardCode,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    Cleave,
    BButton,
    BRow,
    BFormInput,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      phone: "",
      cid: "",
      rid: "",
      wechatid: "",
      wechatname: "",
      remark: "",
      selected: "",
      ChannelOptions: [],
      options: {
        phone: {
          phone: true,
          phoneRegionCode: "CN",
        },
      },
    };
  },
  methods: {
    submit() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .updatebystatus({
              wechatid: this.wechatid,
              channelid: this.selected.value,
              phone: this.phone,
              remark: this.remark,
              cid: this.cid,
              rid: this.rid,
            })
            .then((res) => {
              console.log(JSON.stringify(res));
              if (res.data.code == "0") {
                this.$toast({
                  component: ToastificationContent, 
                  position: "bottom-center",
                  props: {
                    title: `信息录入成功`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `三秒后关闭窗口`,
                  },
                });
                setTimeout(() => {
                  WeixinJSBridge.call("closeWindow");
                }, 3000);
              }
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "bottom-center",
            props: {
              title: `请填写完整资料`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `有资料没有填写完整`,
            },
          });
        }
      });
    },
  },
  created() {
    this.cid = this.$route.query.cid;
    this.rid = this.$route.query.rid;
    this.wechatid = this.$route.query.wechatid;
    useJwt
      .queryFriendid({ cid: this.cid, friendid: this.wechatid })
      .then((res) => {
        console.log("res", JSON.stringify(res.data.nickname));
        if (res.data != null) {
          this.wechatname = res.data.nickname;
        }
      });
    //绑定渠道列表
    useJwt.queryChannelByCid({ cid: this.cid, type: 1 }).then((response) => {
      if (response.data.code == 0) {
        console.log("response", JSON.stringify(response));
        const forArr = response.data.data;
        forArr.forEach((item, i) => {
          this.ChannelOptions.push({ title: item.channelname, value: item.id });
        });
      }
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>